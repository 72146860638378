import { useCallback } from "react";
import { SchemaType, SchemaTypes } from "@properate/common";
import useSWR from "swr";
import {
  createSchema,
  deleteSchema,
  getSchema,
  listSchemas,
  updateSchema,
} from "@/eepApi";

export function useSchemaList(schemaType: SchemaTypes, buildingId: number) {
  const {
    data: list,
    isLoading,
    error,
    mutate,
  } = useSWR([schemaType, buildingId], async ([schemaType, buildingId]) => {
    return (
      await listSchemas(schemaType, { buildingId: buildingId })
    )?.items.filter((schema) => !schema.isArchived);
  });

  const add = useCallback(
    async (schema: SchemaType) => {
      const result = await createSchema(schemaType, schema);
      await mutate([...(list || []), result], false);
      return result;
    },
    [schemaType, list, mutate],
  );

  const update = useCallback(
    (id: string, schema: Partial<SchemaType>) =>
      updateSchema(schemaType, id, schema).then((result) => {
        const updatedList = list?.map((item) =>
          item.snapshotId === id ? result : item,
        );
        return mutate(updatedList, false);
      }),
    [schemaType, list, mutate],
  );

  return {
    list: list || [],
    add,
    update,
    isLoading,
    error,
  };
}

export function useSchema(type: SchemaTypes, id: string) {
  const {
    data: schema,
    isLoading,
    error,
    mutate,
  } = useSWR([type, id], () => {
    return getSchema(type, id);
  });

  const update = useCallback(
    (schema: Partial<SchemaType>) =>
      updateSchema(type, id, schema).then(async (data) => {
        await mutate(data, false);
        return data;
      }),
    [type, id, mutate],
  );

  const archive = useCallback(async () => {
    await deleteSchema(type, id);
  }, [type, id]);

  return {
    schema,
    update,
    archive,
    isLoading,
    error,
  };
}
