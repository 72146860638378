import { useEffect, useState } from "react";
import { Col, Input, Modal, Row } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons/lib";
import {
  useTranslationsWithFallback,
  MessageKey,
} from "@properate/translations";
import { SchemaType, SchemaTypes, SensorInfo } from "@properate/common";
import { CogniteClient, Timeseries } from "@cognite/sdk";
import { sortString } from "@properate/ui";
import { useSchema } from "@/services/schemas";
import { ShowTextEmptyTable } from "@/pages/common/SchemaKpis/elements";
import { DEFAULT_GLOBAL_THEME } from "@/theme/graph";
import { KpiDescription } from "@/components/TechnicalSchema/KpiTable/KpiDescription";
import { KpiValue } from "@/components/TechnicalSchema/KpiTable/KpiValue";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { SchemaKpiTableText } from "@/components/TechnicalSchema/elements";

const MODAL_WIDTH = 960 + 32 + 32;

type TimeseriesInfo = SensorInfo[];

type SelectedSensors = {
  id: string;
  timeseries: number;
};

export interface TechnicalSchemaProps {
  rootAssetId: number;
  assetId: number;
  backgroundImageId?: number;
  timeseriesId: number;
  image?: string;
  name?: string;
  description?: string;
  subBuilding?: string;
  unit?: string;
  type?: string;
  selectedSensors?: SelectedSensors[];
  sensors?: {
    [key: string]: {
      timeseriesInfo?: TimeseriesInfo;
    };
  };
  min?: number;
  max?: number;
  alarmType?: "warning" | "error";
}

const addNameDescriptionToList = (
  ts: Timeseries[],
  list: TechnicalSchemaProps[],
) => {
  return list?.map((selected) => {
    const timeSeriesInfo = ts.find((ts) => selected.timeseriesId === ts.id);
    if (timeSeriesInfo) {
      const { name, description } = timeSeriesInfo;
      return {
        ...selected,
        name,
        description,
      } as TechnicalSchemaProps;
    }
    return selected;
  });
};

interface Props {
  onHide: () => unknown;
  schemaId: string;
  schemaType: SchemaTypes;
  schema: Pick<SchemaType, "selectedSensors" | "sensors">;
}

export const SelectKpisModal = ({
  onHide,
  schemaId,
  schemaType,
  schema,
}: Props) => {
  const { client } = useCogniteClient();
  const t = useTranslationsWithFallback();
  const [search, setSearch] = useState<string>("");
  const [selectedKpis, setSelectedKpis] = useState<TechnicalSchemaProps[]>();
  const [schemaKpis, setSchemaKpis] = useState<TechnicalSchemaProps[]>();
  const { update } = useSchema(schemaType, schemaId);

  function getTranslatedLabelToNorwegian(label?: string): string | undefined {
    if (!label) {
      return undefined;
    }
    const labelRegexpMatches = /[^]+(?=-)/.exec(label);
    const labelReg = labelRegexpMatches ? labelRegexpMatches[0] : "";
    return t.fallback([
      `common.setpoints-query-labels.${labelReg}` as MessageKey,
    ]);
  }

  useEffect(() => {
    const getTimeseries = async (client: CogniteClient) => {
      if (schema) {
        const other: TechnicalSchemaProps[] = [];

        const selected = (schema.selectedSensors || []).map((s) => {
          const timeseries = schema.sensors[s.id]?.timeseriesInfo.find(
            (ts) => ts.id === s.timeseries,
          );
          if (timeseries) {
            const { id, ...timeseriesProps } = timeseries;
            return { ...timeseriesProps, timeseriesId: id, type: s.id };
          }
          return [];
        }) as TechnicalSchemaProps[];

        Object.entries(schema.sensors).forEach((entry) => {
          entry[1]?.timeseriesInfo
            .filter((timeseries) => {
              const hasTimeseriesId =
                timeseries.id !== undefined && timeseries.id !== null;
              const hasSelectedSensor = Array.isArray(schema.selectedSensors)
                ? schema.selectedSensors.some(
                    (selectedSensors) =>
                      selectedSensors.id === entry[0] &&
                      selectedSensors.timeseries === timeseries.id,
                  )
                : false;
              return !hasTimeseriesId || !hasSelectedSensor;
            })
            .forEach(async (timeseries) => {
              const { id, ...timeseriesProps } = timeseries;
              other.push({
                ...(timeseriesProps as TechnicalSchemaProps),
                timeseriesId: id,
                type: entry[0],
              });
            });
        });

        const merge = [...other, ...selected].filter(
          (value, index, self) =>
            index ===
            self.findIndex((t) => t.timeseriesId === value.timeseriesId),
        );
        const query = merge.map((ts) => ({ id: ts.timeseriesId }));

        const retrieveTimeSeries =
          query.length > 0 ? await client.timeseries.retrieve(query) : [];

        const filteredSelected = addNameDescriptionToList(
          retrieveTimeSeries,
          selected,
        );

        const filteredAvailableKpis = addNameDescriptionToList(
          retrieveTimeSeries,
          other,
        );

        setSelectedKpis(filteredSelected);
        const mergedSelectedAvailableKpis: TechnicalSchemaProps[] = [
          ...filteredSelected,
          ...filteredAvailableKpis,
        ];
        const uniqueKpisMap = new Map<number, TechnicalSchemaProps>();

        mergedSelectedAvailableKpis.forEach((value) => {
          uniqueKpisMap.set(value.timeseriesId, value);
        });

        setSchemaKpis(Array.from(uniqueKpisMap.values()));
      }
    };
    getTimeseries(client);
  }, [client, schema]);

  const selectedKPIsIds =
    (selectedKpis && selectedKpis.map((kpi) => kpi.timeseriesId)) || [];

  async function removeSelectedSensor(id: number) {
    const sensorToBeRemoved = selectedKpis?.find(
      (sensor) => sensor.timeseriesId === id,
    );

    if (schemaType && sensorToBeRemoved) {
      await update({
        selectedSensors: (schema?.selectedSensors || []).filter(
          (sensor) =>
            !(sensor.id === sensorToBeRemoved.type && sensor.timeseries === id),
        ),
      });
    }
  }

  async function addSelectedSensor(id: number, type: string) {
    if (schemaType) {
      await update({
        selectedSensors: (schema?.selectedSensors || []).concat([
          {
            id: type,
            timeseries: id,
          },
        ]),
      });
    }
  }

  const searchTerms = search.trim().split(" ");
  const filteredSchemaKpis =
    schemaKpis &&
    schemaKpis.filter((item) => {
      const { type, name, description } = item;
      const translatedLabel = getTranslatedLabelToNorwegian(type);

      return (
        !searchTerms ||
        searchTerms.every(
          (term: string) =>
            (name || "").toLowerCase().includes(term.toLowerCase()) ||
            (description || "").toLowerCase().includes(term.toLowerCase()) ||
            (translatedLabel || "").toLowerCase().includes(term.toLowerCase()),
        )
      );
    });

  return (
    <Modal
      width={MODAL_WIDTH}
      open
      onCancel={() => onHide()}
      onOk={() => onHide()}
      title={t("floor-plan.kpi-table-modal.title")}
      styles={{ body: { height: 619 } }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Input.Search
            allowClear
            style={{ width: 300 }}
            value={search}
            onChange={(event) => {
              if (event.target.value.trim().length === 0) {
                setSearch("");
                return;
              }
              setSearch(event.target.value);
            }}
          />
        </Col>
      </Row>
      {schemaKpis && schemaKpis.length === 0 ? (
        <ShowTextEmptyTable>
          {" "}
          {t("floor-plan.kpi-table-modal.selected-all-label")}
        </ShowTextEmptyTable>
      ) : (
        <TableWithoutDefaultSort
          dataSource={filteredSchemaKpis}
          pagination={false}
          scroll={{ y: 500 }}
          rowKey="timeseriesId"
          columns={[
            {
              title: t("floor-plan.kpi-table-modal.table-column-title.name"),
              dataIndex: "name",
              sorter: ({ name: nameOne }, { name: nameTwo }) =>
                sortString(nameOne, nameTwo),
              render: (_, { name, description, timeseriesId }) => {
                return (
                  <KpiDescription
                    key={timeseriesId}
                    name={name}
                    description={description}
                    search={search}
                  />
                );
              },
            },
            {
              title: t("floor-plan.kpi-table-modal.table-column-title.type"),
              dataIndex: "type",
              render: (_, { type }) => {
                if (type) {
                  const translatedLabel = getTranslatedLabelToNorwegian(type);

                  return (
                    typeof translatedLabel === "string" && (
                      <SchemaKpiTableText>
                        <ProperateHighlighter
                          searchWords={search?.split(" ") || [""]}
                          autoEscape
                          textToHighlight={translatedLabel}
                        />
                      </SchemaKpiTableText>
                    )
                  );
                }
                return null;
              },
            },
            {
              title: t(
                "floor-plan.kpi-table-modal.table-column-title.latest-datapoint",
              ),
              dataIndex: "value",
              render: (_, { timeseriesId, min, max, alarmType }) => {
                return (
                  <SchemaKpiTableText>
                    <KpiValue
                      id={timeseriesId}
                      min={min}
                      max={max}
                      alarmType={alarmType}
                    />
                  </SchemaKpiTableText>
                );
              },
            },
            {
              dataIndex: "timeseriesId",
              width: 64,
              defaultSortOrder: "descend",
              sorter: ({ timeseriesId: idOne }, { timeseriesId: idTwo }) => {
                if (selectedKPIsIds.includes(idOne)) {
                  return selectedKPIsIds.includes(idTwo) ? 0 : -1;
                }
                return selectedKPIsIds.includes(idTwo) ? 1 : 0;
              },
              render: (_, { timeseriesId, type }) =>
                selectedKPIsIds.includes(timeseriesId) ? (
                  <StarFilled
                    style={{
                      color: DEFAULT_GLOBAL_THEME.defaultColor,
                      fontSize: "16px",
                    }}
                    onClick={() => removeSelectedSensor(timeseriesId)}
                  />
                ) : (
                  <StarOutlined
                    style={{
                      color: DEFAULT_GLOBAL_THEME.defaultColor,
                      fontSize: "16px",
                    }}
                    onClick={() => addSelectedSensor(timeseriesId, type!)}
                  />
                ),
            },
          ]}
        />
      )}
    </Modal>
  );
};
